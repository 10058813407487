body {
  background-color: #000000;
}

.hero-section {
  width: 100vw;
  height: 100vh;
  position: relative;

  .hero-text-overlay {
    position: absolute;
    top: 50%;
    transform: translateY(-55%);
    z-index: 120;
    width: 100%;

    h1 {
      text-align: center;
      font-size: 370px;
      color: #3a67c9c4;
    }
  }

  .white_text {
    position: absolute;
    left: 50%;
    top: 57%;
    z-index: 170;

    h1 {
      width: 100%;
      line-height: 86px;
      font-size: 76px;
      color: $white;
      margin: 0 !important;
    }

    .explore-btn {
      color: $white;
      a {
        display: flex;
        align-items: center;
        padding: 8px 16px;
        background: linear-gradient(
          270deg,
          rgba(255, 255, 255, 0) 0%,
          rgba(0, 0, 0, 0.4) 50%,
          rgba(0, 0, 0, 1) 100%
        );
        font-weight: bold;

        span {
          margin-right: 6px;
        }
      }
    }
  }

  .splide {
    .splide__slide {
      width: 100vw;
      height: 100vh;

      .hero-img {
        width: 100%;
        height: 100%;
        position: relative;

        img {
          width: 100%;
          height: 100%;

          object-fit: cover;
          object-position: center;
        }

        &::before {
          content: "";
          width: 100%;
          height: 100%;
          z-index: 100;
          background-image: linear-gradient(
            180deg,
            rgba(0, 0, 0, 0.9) 0%,
            rgba(255, 252, 252, 0) 40%
          );

          position: absolute;
          left: 0;
          top: 0;
        }

        &::after {
          content: "";
          width: 100%;
          height: 100%;
          z-index: 90;
          background-color: #00000020;

          position: absolute;
          left: 0;
          top: 0;
        }
      }
    }
  }

  @media (max-width: 767px) and (orientation: portrait) {
    .hero-text-overlay {
      h1 {
        text-align: center;
        font-size: 120px;
      }
    }

    .white_text {
      left: 45%;
      top: 52%;

      h1 {
        line-height: 34px;
        font-size: 32px;
      }

      .explore-btn {
        a {
          font-size: 14px;
          padding: 8px;
        }
      }
    }
  }
}

.map-section {
  width: 100vw;
  height: 990px;
  position: relative;

  background-image: url("../img/map-bg.png");
  background-position: center center;
  background-repeat: no-repeat;
  background-size: cover;

  color: $white;

  padding: ($space * 2) 14%;

  display: flex;
  flex-direction: column;
  flex-shrink: 0;

  .video-wrapper {
    position: absolute;
    width: 55vw;
    height: 55vh;
    background-color: $white;
    z-index: 100;
    left: 50%;
    transform: translateX(-50%) translateY(-20%);

    background-image: url("../img/hero.jpg");
    background-repeat: no-repeat;
    background-size: cover;
    background-position: center;

    .yt-frame {
      width: 100%;
      height: 100%;
      position: relative;
    }
  }

  .map-wrapper {
    position: relative;
    z-index: 100;
    height: fit-content;
    margin-top: auto;

    .big-txt {
      position: relative;

      .overlay-text-img {
        position: absolute;
        right: -50%;
        top: -30%;
        width: 260px;
        height: 260px;

        img {
          width: 100%;
          height: 100%;
          object-fit: contain;
          object-position: center;
        }
      }

      h1 {
        color: $brand-color;
        font-size: 8em;
        line-height: 160px;
      }
    }

    .sp-text {
      text-transform: uppercase;
      font-size: 48px;
      transform: translateY(-35%);
      margin-left: ($space + 6);

      span {
        color: $brand-color;
      }
    }
  }

  &::before {
    content: "";
    width: 100%;
    height: 100%;
    z-index: 50;
    background: linear-gradient(
      180deg,
      rgba(0, 0, 0, 0.9) 0%,
      rgba(255, 255, 255, 0) 40%,
      rgba(255, 255, 255, 0) 60%,
      rgba(0, 0, 0, 0.5) 100%
    );

    position: absolute;
    left: 0;
    top: 0;
  }

  &::after {
    content: "";
    width: 100%;
    height: 100%;
    z-index: 40;
    background-color: #00000050;

    position: absolute;
    left: 0;
    top: 0;
  }

  @media (max-width: 767px) and (orientation: portrait) {
    padding: ($space * 2) 4%;
    height: 800px;
    background-position: center left;

    .video-wrapper {
      position: absolute;
      width: 90vw;
      height: 220px;
      transform: translateX(-50%) translateY(-40%);
    }

    .map-wrapper {
      .big-txt {
        .overlay-text-img {
          position: absolute;
          left: 25%;
          top: -25%;
          width: 162px;
          height: 162px;
        }

        h1 {
          font-size: 96px;
          line-height: 98px;
        }
      }

      .sp-text {
        font-size: 28px;
        transform: translateY(0);
        margin-left: 0;
      }
    }
  }
}

.about {
  &-section {
    width: 100%;
    min-height: 860px;
    position: relative;
    overflow: hidden;

    background-image: url("https://images.unsplash.com/photo-1610553023063-91489681e538?ixlib=rb-1.2.1&ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&auto=format&fit=crop&w=1974&q=80");
    background-position: center;
    background-repeat: no-repeat;
    background-size: cover;

    padding: ($space + 6) 0;

    display: flex;

    &::before {
      content: "";
      width: 100%;
      height: 100%;
      z-index: 1;
      background: linear-gradient(
        90deg,
        rgba(255, 255, 255, 0) 0%,
        rgba(58, 103, 201, 0.9) 70%,
        rgba(58, 103, 201, 1) 100%
      );

      position: absolute;
      left: 0;
      top: 0;
    }

    @media (max-width: 767px) and (orientation: portrait) {
      padding: ($space + 3) 0;
      min-height: fit-content;

      &::before {
        background: linear-gradient(
          180deg,
          rgba(255, 255, 255, 0) 0%,
          rgba(58, 103, 201, 0.9) 40%,
          rgba(58, 103, 201, 1) 100%
        );
      }
    }
  }

  &-wrapper {
    position: relative;
    color: $white;
    z-index: 2;
    width: 50%;
    margin: auto 0 auto auto;
    overflow: hidden;

    .about-title {
      text-align: right;
      text-transform: uppercase;
      font-size: 94px;
      line-height: 100px;

      span {
        color: transparent;

        -webkit-text-fill-color: transparent;
        -webkit-text-stroke-width: 1px;
        -webkit-text-stroke-color: $white;
      }
    }

    p {
      color: $white;
      text-align: right;
      font-size: 21px;
    }

    @media (max-width: 767px) and (orientation: portrait) {
      width: 100%;

      .about-title {
        text-align: left;
        text-transform: uppercase;
        font-size: 74px;
        line-height: 76px;
      }

      p {
        color: $white;
        text-align: justify;
        font-size: 16px;
        margin-bottom: 0 !important;
        margin-top: 32px;
      }
    }
  }
}

.vision-section {
  width: 100vw;
  height: 1150px;
  position: relative;
  overflow: hidden;

  @media (max-width: 767px) and (orientation: portrait) {
    height: 980px;
  }

  .big-bold-text {
    font-size: 10em;
    height: 150px;
    color: transparent;

    -webkit-text-fill-color: transparent;
    -webkit-text-stroke-width: 1px;
    -webkit-text-stroke-color: lighten($brand-color, 10%);

    &.st {
      position: absolute;
      top: $space + 1;
      right: $space * 3;
    }

    &.dr {
      position: absolute;
      top: 57%;
      left: $space * 3;

      @media (max-width: 767px) and (orientation: portrait) {
        top: 65%;
      }
    }

    &.ef {
      position: absolute;
      bottom: $space + 2;
      left: 20%;

      @media (max-width: 767px) and (orientation: portrait) {
        left: 10%;
      }
    }

    @media (max-width: 767px) and (orientation: portrait) {
      font-size: 92px;
      height: 96px;
    }
  }

  .vision-text {
    position: absolute;
    top: 25%;
    padding: 0 10%;

    h1 {
      font-size: 64px;
      line-height: 72px;
      -webkit-text-fill-color: transparent;
      -webkit-text-stroke-width: 1px;
      -webkit-text-stroke-color: $white;

      text-transform: uppercase;

      span {
        -webkit-text-fill-color: $brand-color;
        -webkit-text-stroke-width: 1px;
        -webkit-text-stroke-color: transparent;
      }
    }

    p {
      color: $white;
      font-size: 16px;
      margin-bottom: $space * 2 !important;
    }

    @media (max-width: 767px) and (orientation: portrait) {
      top: 26%;

      h1 {
        font-size: 48px;
        line-height: 52px;
      }

      p {
        font-size: 14px;
      }
    }
  }
}

@keyframes leaves {
  0% {
    transform: scale(1);
    -webkit-transform: scale(1);
  }
  100% {
    transform: scale(1.1);
    -webkit-transform: scale(1.1);
  }
}

.btn-primary {
  padding: ($space - 0.25) ($space + 0.25);
  background-color: $brand-color;
  color: $white;
  display: flex;
  align-items: center;
  width: fit-content;
  transition: all 0.2s ease-in-out;
  border: none;

  span {
    margin-right: 0.5rem;
  }

  &:hover {
    background-color: darken($brand-color, 10%);
  }

  @media (max-width: 767px) and (orientation: portrait) {
    padding: ($space - 0.5) ($space + 0.5);
    font-size: 14px;
  }
}

.btn-primary-outline {
  padding: ($space - 0.25) ($space + 0.25);
  background-color: transparent;
  color: $white;
  display: flex;
  align-items: center;
  width: fit-content;
  transition: all 0.2s ease-in-out;
  border: 1px solid $brand-color;

  img {
    width: 21px;
    height: 21px;
    object-fit: contain;
    object-position: center;
    margin-right: 8px;
  }

  span {
    margin-right: 0.5rem;
  }

  &:hover {
    background-color: $brand-color;
    color: $white;
  }

  @media (max-width: 767px) and (orientation: portrait) {
    padding: ($space - 0.5) ($space + 0.5);
    font-size: 14px;
  }
}

.products {
  &-section {
    padding: ($space + 6) 0 ($space) 0;

    .product-detail-wrap {
      padding: $space + 2 0;
      color: $white;
      position: relative;
      overflow: hidden;

      @media (max-width: 767px) and (orientation: portrait) {
        padding: $space 0;
      }

      .product-title-wrap {
        padding: 0 10%;

        h1,
        h3 {
          text-transform: uppercase;
        }

        h1 {
          font-size: 56px;
          margin: 0;
        }

        h3 {
          margin-top: 0;
          font-size: 28px;
          span {
            color: $brand-color;
          }
        }

        @media (max-width: 767px) and (orientation: portrait) {
          padding: 0 4%;

          h1 {
            font-size: 32px;
          }

          h3 {
            font-size: 21px;
          }
        }
      }

      p {
        color: $white;
        text-align: justify;
        width: 60%;
        font-size: 16px;

        @media (max-width: 767px) and (orientation: portrait) {
          text-align: left;
          width: 100%;
          font-size: 14px;
        }
      }

      .product-img-wrap {
        width: 100%;
        margin: $space + 1 auto;
        position: relative;
        display: flex;

        img {
          width: 70%;
          margin: 0 auto;

          @media (max-width: 767px) and (orientation: portrait) {
            width: 100%;
          }
        }

        .product-overlay-text {
          position: absolute;
          right: 0;
          bottom: -($space + 4);

          h1 {
            font-size: 12rem;
            line-height: 132px;
            -webkit-text-fill-color: transparent;
            -webkit-text-stroke-width: 1px;
            -webkit-text-stroke-color: $brand-color;
          }

          @media (max-width: 767px) and (orientation: portrait) {
            bottom: -($space + 2);

            h1 {
              font-size: 84px;
              line-height: 86px;
            }
          }
        }
      }

      .product-tech-specs-wrap {
        padding: 0 10%;

        .ac {
          background-color: transparent;
          border: none;
        }

        .ac-header {
          background-color: transparent;
        }

        .ac-trigger {
          font-size: 26px;
          font-family: $font-family-headings;
          text-transform: uppercase;
          padding: 6px 0;
          background-color: transparent !important;
          color: $white;
          display: flex;
          align-items: center;

          img {
            transition: all 0.3s ease-in-out;
          }

          &[aria-expanded="true"] {
            img {
              transform: rotate(90deg);
            }
          }

          span {
            margin-right: 12px;
          }

          &::after {
            display: none;
          }
        }

        .ac-text {
          color: $white;
          padding: 10px 0;
        }

        .tech-spec-row {
          padding: ($space + 1) 0;
          border-bottom: 0.01em solid #363636;

          .tech-spec-title {
            p {
              text-align: left;
              font-weight: bold;
              font-size: 24px;
              margin: 0;
            }
          }

          .tech-spec-details {
            p {
              font-weight: 500;
              font-size: 17px;
              margin-bottom: 4px !important;
              color: #e8e8e8;
              position: relative;
              // width: 100%;
              padding-left: 16px;

              &::before {
                content: "\2022";
                margin-right: 8px;
                font-size: 26px;
                color: $brand-color;
                position: absolute;
                top: 0;
                left: 0;
              }
            }

            small {
              font-size: 14px;
              line-height: 14px;
              color: #cbcbcb;
            }
          }
        }

        @media (max-width: 767px) and (orientation: portrait) {
          padding: $space + 2 4% 0 4%;

          .ac-trigger {
            font-size: 21px;
          }

          .ac-text {
            color: $white;
            padding: 10px 0;
          }

          .tech-spec-row {
            padding: ($space) 0;

            .tech-spec-title {
              p {
                font-size: 21px;
                margin-bottom: 12px;
              }
            }

            .tech-spec-details {
              p {
                font-size: 16px;
                margin-bottom: 4px !important;
              }

              small {
                font-size: 14px;
                line-height: 14px;
              }
            }
          }
        }
      }
    }
  }

  &-wrapper {
    .title {
      font-size: 112px;
      line-height: 128px;
      -webkit-text-fill-color: transparent;
      -webkit-text-stroke-width: 1px;
      -webkit-text-stroke-color: $white;

      text-transform: uppercase;

      margin-bottom: 8px;

      span {
        -webkit-text-fill-color: $brand-color;
        -webkit-text-stroke-width: 1px;
        -webkit-text-stroke-color: transparent;
      }
    }

    .detail {
      color: $white;
      width: 60%;
    }

    @media (max-width: 767px) and (orientation: portrait) {
      .title {
        font-size: 48px;
        line-height: 50px;
      }

      .detail {
        width: 100%;
      }
    }
  }
}

.contact {
  &-section {
    margin-top: $space + 6;
    width: 100%;

    @media (max-width: 767px) and (orientation: portrait) {
      margin-top: $space + 2;
    }
  }

  &-wrapper {
    height: 600px;
    display: flex;
    flex-shrink: 0;
    width: 100%;
    position: relative;

    .google-map-wrap {
      flex-shrink: 0;
      width: 60%;
      display: block;
      height: 100%;

      background-image: url("../img/map.png");
      background-repeat: no-repeat;
      background-size: cover;
    }

    .shadow {
      position: absolute;
      top: 0;
      right: 0;
      bottom: 0;
      width: 45%;
      overflow: hidden;
      padding-left: 50px;
      z-index: 10;
      background: linear-gradient(
        90deg,
        rgba(0, 0, 0, 0) 0%,
        rgba(0, 0, 0, 0.8) 10%,
        rgba(0, 0, 0, 1) 80%,
        rgba(0, 0, 0, 1) 100%
      );
    }

    .map-overlay-wrapper {
      width: 40%;
      background: #000000;
      position: relative;
      z-index: 20;
      padding: $space;

      .title {
        font-size: 4em;
        line-height: 0.8em;
        -webkit-text-fill-color: transparent;
        -webkit-text-stroke-width: 1px;
        -webkit-text-stroke-color: $white;

        text-transform: uppercase;
        margin-bottom: 16px;

        span {
          -webkit-text-fill-color: $brand-color;
          -webkit-text-stroke-color: transparent;
          -webkit-text-stroke-width: 1px;
        }
      }

      p {
        color: $white;
        font-size: 14px;
        margin: 0 4px;
      }

      form {
        margin: 16px -2px;

        textarea {
          min-height: 90px;
          color: #fff;
        }
      }
    }

    @media (max-width: 767px) and (orientation: portrait) {
      height: auto;
      flex-direction: column;

      .google-map-wrap {
        flex-shrink: 0;
        width: 100%;
        height: 420px;
        display: block;
      }

      .shadow {
        display: none;
      }

      .map-overlay-wrapper {
        width: 100%;
        margin-top: 21px;
        position: relative;
        z-index: 20;
        padding: $space;

        .title {
          font-size: 4em;
          line-height: 0.8em;
          -webkit-text-fill-color: transparent;
          -webkit-text-stroke-width: 1px;
          -webkit-text-stroke-color: $white;

          text-transform: uppercase;
          margin-bottom: 16px;

          span {
            -webkit-text-fill-color: $brand-color;
            -webkit-text-stroke-color: transparent;
            -webkit-text-stroke-width: 1px;
          }
        }

        p {
          color: $white;
          font-size: 14px;
          margin: 0 4px;
        }

        form {
          margin: 16px -2px;

          textarea {
            min-height: 90px;
            color: #fff;
          }
        }
      }
    }
  }
}

.picture-note {
  margin-top: 64px;
  p {
    font-size: 14px;
    text-transform: lowercase;
    color: #ffffff;
  }
}

.btn-wrap {
  display: flex;

  a,
  button {
    margin: 0 16px;

    &:first-child {
      margin-left: 0;
    }
    &:last-child {
      margin-right: 0;
    }
  }

  @media (max-width: 767px) and (orientation: portrait) {
    flex-direction: column;

    a,
    button {
      margin: 12px 0;
      width: 100%;
      text-align: center;
      justify-content: center;
    }
  }
}

.gallery-section {
  .splide {
    .splide__slide {
      width: 100vw;
      height: 100vh;

      .hero-img {
        width: 100%;
        height: 100%;
        position: relative;

        img,
        video {
          width: 100%;
          height: 100%;

          object-fit: cover;
          object-position: center;
        }

        &::before {
          content: "";
          width: 100%;
          height: 100%;
          z-index: 100;
          background-image: linear-gradient(
            180deg,
            rgba(0, 0, 0, 0.9) 0%,
            rgba(255, 252, 252, 0) 40%
          );

          position: absolute;
          left: 0;
          top: 0;
        }

        &::after {
          content: "";
          width: 100%;
          height: 100%;
          z-index: 90;
          background-color: #00000020;

          position: absolute;
          left: 0;
          top: 0;
        }
      }
    }
  }
}
