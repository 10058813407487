@use "sass:map";

.nav-container {
  position: fixed;
  z-index: 1000;
  background-color: transparent;
  width: 100vw;
  height: 78px;
  padding: 12px 26px;

  .nav-wrapper {
    height: 100%;
    width: 100%;
    display: flex;
    flex-direction: row;
    align-items: center;
    flex-shrink: 0;

    .nav-logo {
      width: 64px;
      height: 64px;
      padding: 4px;

      img {
        width: 100%;
        height: 100%;
        object-fit: contain;
        object-position: center;
      }
    }

    .nav-links {
      display: flex;
      margin-left: auto;
      color: $white;

      .nav-link {
        font-size: 16px;
        margin: 0 $space;

        &:last-child {
          margin-right: 0;
        }
        &:first-child {
          margin-left: 0;
        }
      }
    }
  }

  @media (max-width: 767px) and (orientation: portrait) {
    padding: 12px;
    .nav-wrapper {
      .nav-links {
        display: none;
      }
    }
  }
}

.quote-link {
  padding: 6px 16px;
  color: $white;
  font-size: 16px;
  border: 1px solid $white;
  margin-left: $space + 1;
  border-radius: 24px;

  @media (max-width: 767px) and (orientation: portrait) {
    margin-left: auto;
  }
}
